import React from 'react';

import pepperChilly from '../../assets/images/pepperChily.png';
import googlePay from '../../assets/icons/googlePay.png';
import applePay from '../../assets/icons/applePay.png';

import style from './footer.module.scss';
import classNames from 'classnames';

const Footer = () => {
  return (
    <footer className={style.footer}>
      <div className={style.footer_info}>
        <h2 className={style.footer_info__title_mobile}>в один клік</h2>
        <span className={style.footer_info__text_mobile}>
          завантажуй додаток
        </span>
        <h2 className={style.footer_info__title}>Завантажуй додаток</h2>
        <span className={style.footer_info__text}>
          Більше ніж система лояльності у вашому смартфоні.
        </span>
        <div className={style.footer_links}>
          <a
            className={classNames(style.footer_links__apple, style.apple)}
            href='https://apps.apple.com/us/app/pomodoro/id6444343040'
          >
            <img src={applePay} alt='applePay' />
          </a>
          <a
            className={style.footer_links__google}
            href='https://play.google.com/store/apps/details?id=club.eatery.pomodoro'
          >
            <img src={googlePay} alt='googlePay' />
          </a>
        </div>
      </div>
      <div className={style.footer_coppyright}>
        <span className={style.footer_coppyright__info}>
          Copyright © 2023 by Pomodoro. All Rights Reserved
        </span>
        <span className={style.footer_coppyright__made}>
          Made with ❤️ by <strong>Eatery Club</strong>
        </span>
        <img
          src={pepperChilly}
          alt='pepperChilly'
          className={style.pepperChilly}
        />
      </div>
    </footer>
  );
};

export default Footer;
