import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import useWindowSize from './useWindowsSize';

import Delivery from './components/delivery';
import Footer from './components/footer';
import Menu from './components/menu';

import pomodoroLogo from './assets/images/pomodoroLogo.png';

import style from './styles/main.module.scss';

function App() {
  const [deliveryActive, setDeliveryActive] = useState(false);
  const [menuActive, setMenuActive] = useState(false);
  const [loader, setLoader] = useState(false);

  const { windowWidth } = useWindowSize();

  useEffect(() => {
    window.addEventListener('pageshow', function (event) {
      if (event.persisted) {
        setLoader(false);
      }
    });
  });

  const menuActivator = (status) =>
    setMenuActive(windowWidth <= 980 ? false : status);
  const deliveryActivator = (status) =>
    setDeliveryActive(windowWidth <= 980 ? false : status);

  const openLoader = () => setLoader(true);
  const renderLoader = () =>
    loader && (
      <div className={style.wrapper_loader}>
        <img src={pomodoroLogo} alt='pomodoroLoader' className={style.loader} />
      </div>
    );

  const renderContent = () =>
    !loader && (
      <>
        <Menu
          active={menuActive}
          activator={menuActivator}
          deliveryActive={deliveryActive}
          loader={openLoader}
        />
        <Delivery
          active={deliveryActive}
          menuActive={menuActive}
          activator={deliveryActivator}
          loader={openLoader}
        />
        <Footer />
      </>
    );
  return (
    <>
      <Helmet>
        <meta name='viewport' content='width=device-width, initial-scale=1.0' />
        <link rel='preconnect' href='https://fonts.googleapis.com' />
        <link rel='preconnect' href='https://fonts.gstatic.com' crossorigin />
        <link
          href='https://fonts.googleapis.com/css2?family=Commissioner:wght@400;700;900&family=Montserrat+Alternates:wght@400;500;700;900&display=swap'
          rel='stylesheet'
        />
        <link rel='preconnect' href='https://fonts.googleapis.com' />
        <link rel='preconnect' href='https://fonts.gstatic.com' crossorigin />
        <link
          href='https://fonts.googleapis.com/css2?family=Commissioner:wght@400;700;900&family=Montserrat+Alternates:wght@700&family=Montserrat:wght@300;600&display=swap'
          rel='stylesheet'
        />
        <link
          href='https://fonts.cdnfonts.com/css/sf-pro-display'
          rel='stylesheet'
        />
      </Helmet>
      <div className={style.container}>
        {renderLoader()}
        {renderContent()}
      </div>
    </>
  );
}

export default App;
