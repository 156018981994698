import { useState } from 'react';
import useWindowSize from '../../hooks/useWindowSize.jsx';

import vegetables from '../../assets/images/vegetables.png';
import pizza from '../../assets/images/bigPizza.png';
import pepperMobile from '../../assets/images/peperMobile.png';
import salatMobile from '../../assets/images/salatMobileDelivery.png';
import salatForPizza from '../../assets/images/saladForPizza.png';
import redPepper from '../../assets/images/redPepper.png';
import pizzaMobile from '../../assets/images/pizzaMobile.png';
import arrow from '../../assets/icons/arrow.svg';

import style from './delivery.module.scss';
import classNames from 'classnames';

const Delivery = (props) => {
  const { active, menuActive, activator, loader } = props;

  const { windowWidth } = useWindowSize();

  const [showList, setShowList] = useState(false);

  const handlerShowList = () => {
    setShowList(!showList);
  };

  const renderListPhoneTablet = () =>
    windowWidth > 720 && (
      <ul
        className={classNames(style.list_phones, {
          [style.active_list]: showList,
        })}
      >
        <li className={style.first}>+38 (048) 700 00 22</li>
        <li>+38 (093) 700 00 22</li>
        <li>+38 (099) 700 00 22</li>
        <li>+38 (097) 700 00 22</li>
      </ul>
    );

  const renderListPhoneMobile = () =>
    windowWidth <= 720 && (
      <ul
        className={classNames(style.list_phones, {
          [style.active_list]: showList,
        })}
      >
        <li className={style.first}>
          <a href='tel:+38 (048) 700 00 22' className={style.mobile_link}>
            +38 (048) 700 00 22
          </a>
        </li>
        <li>
          <a href='tel:+38 (093) 700 00 22' className={style.mobile_link}>
            +38 (093) 700 00 22
          </a>
        </li>
        <li>
          <a href='tel:+38 (099) 700 00 22' className={style.mobile_link}>
            +38 (099) 700 00 22
          </a>
        </li>
        <li>
          <a href='tel:+38 (097) 700 00 22' className={style.mobile_link}>
            +38 (097) 700 00 22
          </a>
        </li>
      </ul>
    );

  return (
    <div className={style.delivery}>
      <img
        src={pepperMobile}
        alt='pepperMobile'
        className={style.pepperMobile}
      />
      <img src={salatMobile} alt='salatMobile' className={style.salatMobile} />
      <div
        className={classNames(style.width, {
          [style.active_width]: active,
        })}
      ></div>
      <div className={style.delivery_vegetables}>
        <img src={vegetables} alt='vegetables' />
      </div>
      <div className={style.delivery_info}>
        <span className={style.delivery_info__text}>Замовити</span>
        <h2
          className={classNames(style.delivery_info__title, {
            [style.less]: menuActive,
          })}
        >
          Доставку
        </h2>
        <div className={style.delivery_buttons}>
          <a
            href='https://delivery.pomodoro.od.ua/'
            className={style.delivery_buttons__order}
            onMouseOver={() => activator(true)}
            onMouseLeave={() => activator(false)}
            onClick={loader}
          >
            Замовити онлайн
          </a>
          <button
            className={classNames(style.delivery_buttons__ring, {
              [style.active_phone]: showList,
            })}
            onMouseOver={() => activator(true)}
            onMouseLeave={() => activator(false)}
            onClick={handlerShowList}
          >
            Зателефонувати
            <img src={arrow} alt='arrow' className={classNames(style.arrow, {
              [style.active_arrow]: showList
            })} />
          </button>
          {renderListPhoneTablet()}
          {renderListPhoneMobile()}
        </div>
      </div>
      <div className={style.delivery_pizza_wrapper}>
        <img src={redPepper} alt='redPepper' className={style.pepper} />
        <img
          src={salatForPizza}
          alt='salatForPizza'
          className={style.salatForPizza}
        />
        <img
          src={pizza}
          alt='pizza'
          className={classNames(style.pizza, {
            [style.rotate]: menuActive,
          })}
        />
      </div>
      <div
        className={classNames(style.pizzaMobile_wrapper, {
          [style.down]: showList,
        })}
      >
        <img
          src={pizzaMobile}
          alt='pizzaMobile'
          className={style.pizzaMobile}
        />
      </div>
    </div>
  );
};

export default Delivery;
