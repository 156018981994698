import pizzaAndVegetables from '../../assets/images/pizzaAndVegetables.png';
import vegetablesMenu from '../../assets/images/vegetablesMenu.png';
import pomodoroLogo from '../../assets/images/pomodoroLogo.png';
import salatMobile from '../../assets/images/salatMobile.png';
import tomatoMobile from '../../assets/images/tomatoMobile.png';

import style from './menu.module.scss';
import classNames from 'classnames';

const Menu = (props) => {
  const { deliveryActive, active, activator, loader } = props;

  return (
    <div className={style.menu}>
      <div
        className={classNames(style.width, {
          [style.active_width]: active,
        })}
        style={{ zIndex: `${active ? 110 : 0}` }}
      ></div>
      <img src={tomatoMobile} alt='tomatoMobile' className={style.menu_tomat} />
      <img src={salatMobile} alt='salatMobile' className={style.menu_salat} />
      <div className={style.menu_vegetables}>
        <img src={vegetablesMenu} alt='vegetablesMenu' />
      </div>
      <div className={style.menu_info}>
        <div className={style.menu_info__description}>
          <span className={style.menu_info__text}>Переглянути</span>
          <h1
            className={classNames(style.menu_info__title, {
              [style.less]: deliveryActive,
              [style.biggest]: active,
            })}
          >
            меню
          </h1>
          <a
            // href='https://delivery.pomodoro.od.ua/1?tableNumber='
            href='/menu-bezkontaktnoe.pdf'
            className={style.menu_info__button}
           /*  onClick={loader} */
            onMouseOver={() => activator(true)}
            onMouseLeave={() => activator(false)}
          >
            Перейти до меню
          </a>
        </div>
        <img
          src={pomodoroLogo}
          alt='pomodoroLogo'
          className={classNames(style.menu_info__image, {
            [style.rotate]: deliveryActive,
            [style.move]: active,
          })}
        />
      </div>
      <img
        className={style.menu_image}
        src={pizzaAndVegetables}
        alt='pizzaAndVegetables'
      />
    </div>
  );
};

export default Menu;
